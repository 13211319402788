import * as React from "react";
import { Helmet } from "react-helmet";
import { Layout } from '../components';


// markup
const TermsOfService = ({ location }) => {


	return (
		<Layout location={location}>

			<Helmet>
				<link href="/assets/css/privacy-policy--and--terms-of-service--and--conflict-resolution.css" rel="stylesheet" type="text/css" />
            </Helmet>



			<section data-name="layout" id={'terms_of_service_page'}>
				<div className={'page-content container-fluid col-10 col-xl-8'}>

					<div className={'title'}>terms of service</div>
					<div className={'text-content'}>
						This version was last updated December the 14th <strong>2021</strong >.
						<br /><br />
						Welcome to the Ephemeral Ethernal website, owned and operated by <strong>Ethernal
							Ephemeral, Lda</strong>.
							<br /><br />
						Please read the following terms and conditions carefully as they apply to all activities
						on our platform.
						<br /><br />
						If there is anything within these terms and conditions that you do not understand,
						please contact us at info@ephemeralethernal.com. If you do not agree to these
						terms and conditions, you must not use our website.
						<br /><br /><br />
					</div>


					<div className={'subtitle'}>About Us</div>
					<div className={'text-content'}>
						Ephemeral Ethernal is an NFT platform, including original works by a wide selection
						of contemporary artists articulating exceptionally poignant and unique creative
						voices. Emerging from the growing debate surrounding the materiality, perpetuity
						and inherent value of artworks, along with the very nature of art itself, it represents a
						provocation: plunging into the future and questioning the structures that support our
						concept and experience of modernity.
						<br /><br />
						Composed exclusively of NFT-based works, Ephemeral Ethernal fully embodies the
						spirit of this new technology, upending the rules of digital ownership and clearing the
						way towards the end of ephemeral art, evolving or even elevating it into a new plain
						of existence, akin to that of what was once understood as the ether: weightless,
						transparent, frictionless, undetectable chemically or physically, and literally
						permeating all matter and space.
						<br /><br />
						By using the Ephemeral Ethernal website, you are accepting and agreeing to these
						terms and conditions, our privacy policy and cookie policy. Please note that any
						other terms to be referenced in this document may become available further on,
						subject to notice by us.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>The Website</div>
					<div className={'text-content'}>
						This website is operated by Ephemeral Ethernal, Lda, trading as Ephemeral Ethernal
						©. We are registered in Portugal under VAT number 516670522 and have our
						registered office at Rua Vale Formoso de Cima, n. º 1 tornejando para a rua Zófimo
						Pedroso, n. º 26 a 30, 1950-265 Lisboa, Portugal
						<br /><br />
						From time-to-time Ephemeral Ethernal may make limited time or other special offers
						available which are also subject to the terms and conditions stated at the time of
						purchasing a piece. In the event of any conflict between these terms and conditions
						and the special offer conditions, the special offer conditions shall apply.
						<br /><br /><br />
					</div>


					<div className={'subtitle'}>Access to Ephemeral Ethernal</div>
					<div className={'text-content'}>
						To simply visit and access Ephemeral Ethernal Drops, you don't need to be
						registered on our website. If you would like to place an order, you are required to be
						registered with a user account.
						<br /><br />
						You must be 18 years or older to use and place orders.
						<br /><br />
						By visiting and/or registering on our website, the collection and processing of your
						personal data is governed by our privacy policy, so please give it a read.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>The Terms &amp; Conditions</div>
					<div className={'text-content'}>
						The Terms and Conditions govern your access to and use of the Ephemeral Ethernal
						website to purchase digital assets, our online and/or mobile services, and software
						provided on or in connection with those services (collectively, the “Service”).
						<br /><br />
						This Service allows you to purchase Crypto Assets. You may only purchase by
						linking your digital wallets on supported bridge extensions such as MetaMask (<a href="https://metamask.io/" target="_blank" rel="noreferrer">https://metamask.io/</a>). MetaMask is an electronic wallet, which allows you to
						purchase, store, and engage in transactions using Ethereum cryptocurrency. Once
						you submit an order to purchase a unique digital asset, your order is passed on to
						the applicable extension, which completes the transaction on your behalf.
						<br /><br />
						“Crypto Assets” refers to unique non-fungible tokens, implemented on the Ethereum
						blockchain (the “Ethereum Platform”) using smart contracts.
						<br /><br />
						All transactions initiated through our service are facilitated and run by third-party
						electronic wallet extensions, and by using our services you agree that you are
						governed by the Terms of Service and Privacy Policy for the applicable extensions.
						For Metamask, those terms are available at (<a href="https://metamask.io/terms.html" target="_blank" rel="noreferrer">https://metamask.io/terms.html</a>) and (<a href="https://metamask.io/privacy.html" target="_blank" rel="noreferrer">https://metamask.io/privacy.html</a>).
						<br /><br />
						Because we have a growing number of services, we sometimes need to describe
						additional terms for specific services. Those additional terms and conditions, which
						are available with the relevant services, then become part of your agreement with us
						if you use those services.
						<br /><br />
						By clicking on the “I accept” button, completing the account registration process,
						using our services and/or purchasing crypto assets, you agree to be bound by these
						terms and all of the terms incorporated herein by reference.
						<br /><br />
						If you do not agree to these Terms, you may not access or use the Service or
						purchase the Crypto Assets.
						<br /><br />
						Ephemeral Ethernal reserves the right to change or modify these Terms at any time
						and at our sole discretion. If we make changes to these Terms, we will provide notice
						of such changes. By continuing to access or use the Service, you confirm your
						acceptance of the revised Terms and all of the terms incorporated therein by
						reference. We encourage you to review the Terms frequently to ensure that you
						understand the terms and conditions that apply when you access or use the Service.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Force Majeure</div>
					<div className={'text-content'}>
						A Force Majeure Event means any unforeseeable event that, temporarily or
						permanently, prevents us from performing our obligations, including but not limited to
						extremely adverse weather conditions, natural disaster, war, threat or preparation for
						war, terrorist attack, riots, failure of machinery, computers or vehicles, pandemics or
						public health emergencies.
						<br /><br />
						Ephemeral Ethernal will not be liable or responsible for any failure to perform or
						delay in the performance of any of its obligations that is caused by a Force Majeure
						Event.
						<br /><br />
						If a Force Majeure Event prevents us from complying with this document, we will
						reach you by email or telephone in order to inform you about our solution.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Copyright Policy</div>
					<div className={'text-content'}>
						We respect the intellectual property rights of others and expect users of the
						Ephemeral Ethernal website to do the same.
						<br /><br />
						All artworks available on our website for sale are original and were entirely and
						exclusively produced by the respective artists mentioned therein. All copyrights
						belong to the respective artists.
						<br /><br />
						If you have a belief that any content in our store infringes your intellectual property,
						let us know at <a href="mailto:info@ephemeralethernal.com?subject=Website%20-%20Terms%20of%20Service">info@ephemeralethernal.com</a>.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Limitation of Liability</div>
					<div className={'text-content'}>
						Ephemeral Ethernal, Lda. trading as Ephemeral Ethernal © does not guarantee that
						this website will be free from viruses or that this website cannot be tempered by third
						parties. In no event will Ephemeral Ethernal, Lda. be liable for any damages
						whatsoever arising out of or related to this website.
						<br /><br />
						We try to follow the most adequate measures regarding the security of the website
						and the accuracy of content, but no responsibility can be taken for any error or
						omission.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Jurisdiction and Applicable Law</div>
					<div className={'text-content'}>
						These terms and conditions are subject to the laws of Portugal and the Courts of
						Portugal shall be the competent jurisdiction over any claims arising from or in relation
						to them.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Changes to these terms and conditions</div>
					<div className={'text-content'}>
						Our terms and conditions may be updated regularly as our service changes with
						time. You should check this page from time to time to take notice of any changes
						that we made as they govern the use of Ephemeral Ethernal website.
						<br /><br />
						Some of the provisions contained in these terms and conditions may also be
						removed, in whole or in part, with or without notice, at our sole discretion. We
						reserve this right as well as the right to add and modify any provisions.
						<br /><br /><br />
					</div>

					<div className={'subtitle'}>Contact us</div>
					<div className={'text-content'}>
						If you have any questions, suggestions and comments, you should address them to
						info@ephemeralethernal.com. We will try to get back to you as soon as possible and
						always within 30 days.
						<br /><br /><br />

						All content 2021 © by Ephemeral Ethernal, Lda. trading as Ephemeral Ethernal.
						<br /><br />
						All rights reserved.
						<br /><br /><br />
					</div>

				</div>


			</section >
		</Layout >
	)
}

export default TermsOfService
